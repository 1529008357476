<template>
	<div class="textList">
		<div class="lis">
			<a v-if="msg.jump == 'url'" :href="msg.url" :title="msg.title" target="_blank">
				<p><em>• </em>{{ msg.title }}</p>
				<time>{{ msg.timeline.substring(0, 10) }}</time>
			</a>

			<a v-else :href="msg.file_url" :title="msg.title" target="_blank">
				<p><em>• </em>{{ msg.title }}</p>
				<time>{{ msg.timeline.substring(0, 10) }}</time>
			</a>
		</div>
	</div>
</template>

<script>
export default {

	name: 'textList',
	props: {
		'msg': {
			type: Object,
			default() {
				return [];
			}
		},
		'id': {
			type: String,
			default: ""
		},
		'sx': {
			type: String,
			default: ""
		},
		'index': {
			type: String,
			default: ""
		},
		'types': {
			type: String,
			default: ""
		},
		'tzfs': {
			type: String,
			default: "cont"
		}
	},
	created() {
		// console.log(this.msg, 12)
	}
}
</script>

<style>
.fff {
	position: absolute;
}
</style>
