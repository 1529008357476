<template>
    <li :class="itemClasses">
        <div :class="tailClasses"></div>
<!--        <div class="date"><slot name="date"></slot></div>-->
        <div :class="headClasses" :style="customColor" ref="dot"><slot name="dot"></slot></div>
        <div :class="contentClasses">
            <slot></slot>
        </div>
    </li>
</template>
<script>

const prefixCls = 'ivu-timeline';

export default {
    name: 'TimelineItem',
    props: {
        color: {
            type: String,
            default: 'blue'
        },
        isLoad:{
            type:Boolean,
            default: false
        }
    },
    data () {
        return {
            dot: false
        };
    },
    mounted () {
        this.dot = this.$refs.dot.innerHTML.length ? true : false;
    },
    computed: {
        itemClasses () {
            return `${prefixCls}-item`;
        },
        tailClasses () {
            return `${prefixCls}-item-tail`;
        },
        headClasses () {
            return [
                !this.isLoad?`acme-item-head`:'',
                `${prefixCls}-item-head`,
                {
                    [`${prefixCls}-item-head-custom`]: this.dot,
                    [`${prefixCls}-item-head-${this.color}`]: this.headColorShow
                }
            ];
        },
        headColorShow () {
            return this.color == 'blue' || this.color == 'red' || this.color == 'green';
        },
        customColor () {
            let style = {};
            if (this.color) {
                if (!this.headColorShow) {
                    style = {
                        'color': this.color,
                        'border-color': this.color
                    };
                }
            }

            return style;
        },
        contentClasses () {
            return `${prefixCls}-item-content`;
        }
    },
};
</script>

<style>
/*新定义实现时间轴样式*/
.acme-item-head{
    background: #2BB7B3;
}
.ivu-timeline-item-head-custom{
    padding-top:0;
}
/*.date{*/
/*    position: absolute;*/
/*    left:0;right:0;*/
/*}*/
/*.ivu-timeline-item-content{*/
/*    padding-left: 100px;*/
/*}*/
</style>