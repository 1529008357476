<template>
	<div class="ztnrbg">
		<div class="index_mv ztnr">
			<leftMeun :name="name" :menuid="id"></leftMeun>
			<div class="right_nr">
				<div class="list category_news_article" style="border: 0; margin: 0">
					<rightMeun :name="name" :menuid="id" @render="renderArticle"></rightMeun>
					<template v-if="currentMenu.showtype == 'time'">
						<h2 class="time-title">{{ name }}</h2>
						<div class="time-desc"></div>
						<Timeline>
							<AcmeTimelineItem v-for="(item, index) in list" :key="index" v-if="isShowAll || index < 5">
								<div class="time-line-body">
									<div class="date">{{ item.date }}</div>
									<div class="content" v-html="item.content"></div>
								</div>
							</AcmeTimelineItem>
							<AcmeTimelineItem :isLoad="true">
								<div class="loadmore" slot="dot" @click="loadMore">
									<Icon type="ios-arrow-round-down" size="40" @click="loadMore" />
								</div>
							</AcmeTimelineItem>
						</Timeline>
					</template>

					<template v-else>
						<div v-if="ftlView == true">
							<div class="newsTit" style="font-weight: 700; font-size: 26px">创建发展访谈录</div>
							<div
								style="padding-top: 20px; text-indent: 30px; font-size: 17px; line-height: 30px;font-family:宋体">
								由南方科技大学宣传与公共关系部牵头完成，共访谈了40多位学校创建和发展期间重要的决策者、参与者、见证者，深入挖掘南科大创建发展过程中的感人故事，展现深圳及南科大人勇敢开拓、执着前行的追梦精神；同时客观呈现南科大创建发展的历程，为中国高教改革及新型研究型大学建设提供典型样本和经验。
							</div>
							<div style="text-align: center; padding: 16px 0; font-size: 20px; font-weight: 600;">
								此内容请见南方科技大学官网
							</div>
						</div>
						<div class="leader">
							<div class="leaderList" v-if="leader && leaderList.length > 0"
								style="display: flex;  justify-content: center">
								<div class="list" v-for="(li, index) in leaderList">
									<a v-if="li.url" :href="li.url" :title="li.title" target="_blank">
										<div class="img">
											<img :src="li.thumb" alt="" />
										</div>
										<div class="title">
											<div class="name" style="font-size: 12px">姓名 {{ li.title }}</div>
											<div class="desc" v-if="li.description.length > 0" style="font-size: 12px">
												<div class="desclist" v-for="(item1, index1) in li.description">
													<img :src="item1.icon" alt="" style="display: inlin-block" />
													<!-- <div>{{item1.name + ':'}}</div> -->
													<div style="font-size: 12px">{{ item1.content }}</div>
												</div>
												<!-- <span></span> -->
											</div>
										</div>
									</a>
									<a v-else :href="'/details/text/' +
										li.id +
										'?type=page' +
										'&pid=3&catid=' +
										li.catid +
										'&sx=' +
										sx +
										'&index=' +
										index +
										'&names=dddd'
										" class="custom_roter_link">
										<div class="img">
											<img :src="li.thumb" alt="" />
										</div>
										<div class="title">
											<div class="name" style="font-size: 12px">姓名 {{ li.title }}</div>
											<div class="desc" v-if="li.description.length > 0" style="font-size: 12px">
												<div class="desclist" v-for="(item1, index1) in li.description">
													<img :src="item1.icon" alt="" style="display: inlin-block" />
													<!-- <div>{{item1.name + ':'}}</div> -->
													<div style="font-size: 12px">{{ item1.content }}</div>
												</div>
												<!-- <span></span> -->
											</div>
										</div>
									</a>
								</div>
							</div>
							<div class="leaderList" v-if="leader && list.length > 0">
								<div class="list" v-for="(li, index) in list" style="padding-top: 20px">
									<a v-if="li.url" :href="li.url" :title="li.title" target="_blank">
										<div class="img">
											<img :src="li.thumb" alt="" />
										</div>
										<div class="title">
											<div class="name" style="font-size: 12px">姓名 {{ li.title }}</div>
											<div class="desc" v-if="li.description.length > 0" style="font-size: 12px">
												<div class="desclist" v-for="(item1, index1) in li.description">
													<img :src="item1.icon" alt="" style="display: inlin-block" />
													<!-- <div>{{item1.name + ':'}}</div> -->
													<div style="font-size: 12px">{{ item1.content }}</div>
												</div>
												<!-- <span></span> -->
											</div>
										</div>
									</a>
									<a v-else :href="'/details/text/' +
										li.id +
										'?type=page' +
										'&pid=3&catid=' +
										li.catid +
										'&sx=' +
										sx +
										'&index=' +
										index

										" class="custom_roter_link">
										<div class="img">
											<img :src="li.thumb" alt="" />
										</div>
										<div class="title">
											<div class="name" style="font-size: 12px">姓名 {{ li.title }}</div>
											<div class="desc" v-if="li.description.length > 0" style="font-size: 12px">
												<div class="desclist" v-for="(item1, index1) in li.description">
													<img :src="item1.icon" alt="" style="display: inlin-block" />
													<!-- <div>{{item1.name + ':'}}</div> -->
													<div style="font-size: 12px">{{ item1.content }}</div>
												</div>
												<!-- <span></span> -->
											</div>
										</div>
									</a>
								</div>
							</div>
							<!-- 讲解员 -->
							<div class="threeList" v-else-if="isExplain">
								<div class="list" v-for="(li, index) in list">
									<a v-if="li.url" :href="li.url" :title="li.title" target="_blank">
										<div class="img">
											<img :src="li.thumb" alt="" />
										</div>
										<div class="title">{{ li.title }}</div>
									</a>
									<a v-else :href="'/details/text/' +
										li.id +
										'?type=page' +
										'&pid=7&catid=120' +
										'&sx=' +
										sx +
										'&index=' +
										index +
										'&names=dddd'
										" class="custom_roter_link">
										<div class="img">
											<img :src="li.thumb" alt="" />
										</div>
										<div class="title">{{ li.title }}</div>
									</a>
								</div>
							</div>
							<!-- 两个图文 -->
							<div class="newsList" style="flex: 1; width: 100%" v-else-if="isImgList">
								<news v-for="(li, index) in list" :key="index" :name="name" :sx="sx" :index="indexs"
									:id="id" :msg="li" types="bggk" />
							</div>
							<!-- 四级菜单 -->
							<div v-else-if="fourList.length">
								<div v-if="v.list.length > 0" v-for="(v, k) in fourList">
									<h2>{{ v.obj.title }}</h2>
									<list v-for="(li, index) in v.list" :key="index" :name="name" :sx="sx"
										:index="indexs" :id="id" :msg="li" types="bggk" />
								</div>
							</div>
							<!-- 展览咨询 -->
							<div class="show2" v-else-if="isZxzl">
								<div class="indexImg22" style="min-height: 200px">
									<div v-if="zxzlList">
										<Carousel loop arrow="never" :height=300 autoplay w="100%"
											v-if="zxzlList.length > 0">
											<CarouselItem v-for="(item1, index1) in zxzlList" :key="index1">
												<a v-if="item1.jump == 'url'" :href="item1.url" target="_blank">
													<div class="demo-carousel1">
														<img :src="item1.thumb" height="100%" width="100%" />
													</div>

												</a>
												<a :href="'/details/text/' +
													item1.id +
													'?type=page' +
													'&pid=129&catid=' +
													item1.catid +
													'&sx=' +
													sx +
													'&index=' +
													index
													">
													<div class="demo-carousel1">
														<img :src="item1.thumb" />
													</div>
												</a>
											</CarouselItem>
										</Carousel>
									</div>
									<div v-else>载入中...</div>
								</div>
								<div class="showLists">
									<div v-for="(item, index1) in list" class="showLists-content">
										<a v-if="item.url" :href="item.url" :title="item.title" target="_blank">
											<div class="left-pic">
												<img :src="item.thumb" alt="" />
											</div>
											<div class="right-title">
												<div class="title">
													{{ item.title }}
												</div>
												<div class="desc">
													{{ item.description }}
												</div>
											</div>
										</a>
										<a v-else :href="'/details/text/' +
											item.id +
											'?type=page' +
											'&pid=129catid=' +
											item.catid +
											'&sx=' +
											sx +
											'&index=' +
											index
											">
											<div class="left-pic">
												<img :src="item.thumb" alt="" />
											</div>
											<div class="right-title">
												<div class="title">
													{{ item.title }}
												</div>
												<div class="desc">
													{{ item.description }}
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>

							<template v-else-if="ftlView == true">
								<list1 v-for="(li, index) in list" :key="index" :name="name" :sx="sx" :index="indexs"
									:id="id" :msg="li" types="bggk" />
							</template>

							<template v-else>
								<list v-for="(li, index) in list" :key="index" :name="name" :sx="sx" :index="indexs"
									:id="id" :msg="li" types="bggk" />

								<div v-if="isShowDonateLink" class="textList" style="margin-top:10px;">
									<div class="lis" style="border:none">
										<a href="/juan_zeng">
											<p style="color:#ff7100;font-size:16px;"><em>•
												</em><strong>南方科技大学文博中心征集文物藏品登记</strong>
											</p>
										</a>
									</div>
								</div>
							</template>
						</div>

						<div class=" pages" style="margin-top: 50px">
							<Page v-if="count > 10" :total="count" @on-change="chan" />
						</div>
					</template>
				</div>
			</div>
			<newRightMeun></newRightMeun>
		</div>
	</div>
</template>
<script>
var _hmt = _hmt || [];
(function () {
	var hm = document.createElement('script');
	hm.src = 'https://hm.baidu.com/hm.js?819d608e55a54ab96615bb5d06bb6230';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
import moment from 'moment';
import list from '@/components/textList.vue';
import list1 from '@/components/textList1.vue';
import news from '@/components/tpList.vue';
import leftMeun from '@/components/leftMeun.vue';
import rightMeun from '@/components/rightMeun.vue';
import newRightMeun from '@/components/newRightMeun.vue';
import AcmeTimelineItem from '@/components/acme/timeline-item';
import ScaleDiv from '@/components/ScaleDiv.vue';
import arctype from '../../config/arctype';

export default {
	components: {
		list,
		list1,
		news,
		leftMeun,
		rightMeun,
		newRightMeun,
		ScaleDiv,
		AcmeTimelineItem,
	},
	data() {
		return {
			id: this.$route.query.pid,
			indexs: this.$route.query.index,
			sx: this.$route.query.sx,
			list: [],
			page: 1,
			count: 0,
			name: '',
			chickid: this.$route.params.chickid,
			leader: false, //领导
			leaderList: [], //领导列表
			currentMenu: {},
			isShowAll: false,
			isImgList: false, //图文显示 2 行
			childrenMenu: [],
			fourList: [], //四级
			isFour: false,
			isExplain: false, //是否详解元
			isZxzl: false, //是否咨询展览
			zxzlList: [], //咨询展览轮播图
			ftlView: false, //创建访谈录
			windowHeight: 600,
			windowWidth: 600,
			zzbbPadding: 20,
			isShowDonateLink: false,
		};
	},
	watch: {
		$route: {
			handler: function (val) {
				// console.log('==_______________-====================');
				let { navList, navIndexs } = this.$store.state;
				let tow = navList[navIndexs[0]].children[navIndexs[1]];
				let three = {};
				this.childrenMenu = null;
				//console.log('towtowtowtowtowtowtow', tow);
				if (tow.children) {
					three = tow.children;
					if (three[navIndexs[2]].children) {
						this.childrenMenu = three[navIndexs[2]].children;
					}
					// console.error(three, navIndexs[2], three[navIndexs[2]].children);
				}

				this.currentMenu = three;
				this.types();
			},
			// 深度观察监听
			deep: true,
		},
	},
	computed: {},
	beforeUpdate(){
		this.isShowDonateLink	= this.$route.query.catid && this.$route.query.catid==arctype.WEB_BO_JUAN_ZENG;
	},
	mounted() {
		this.types();
		
	},
	methods: {
		renderArticle(e) {
			console.log(e);
		},
		loadMore() {
			this.isShowAll = true;
		},
		chan(page) {
			this.page = page;
			this.lists();
		},
		lists() {
			this.list = [];
			this.fourList = [];
			this.leaderList = [];
			this.zxzlList = [];
			this.isZxzl = false;
			this.isFour = false;
			this.isImgList = false;
			this.isExplain = false;
			this.ftlView = false;
			let { query, params } = this.$route;
			// console.error(query, params);
			//判断新闻列表
			if (query.type == 'news') {
				var catid = query.catid || 0;
			} else {
				var catid = params.catid || 0;
			}
			//筛选四级
			if (query.catid == this.arctype.GUI_DANG_ZHI_NAN) {
				let { navList } = this.$store.state;
				this.childrenMenu = navList[2].children[2].children[0].children;
			}
			if (query.catid == this.arctype.FANG_TAN_LU) {
				this.ftlView = true;
				console.log('访谈录');
			}
			if (this.childrenMenu && this.childrenMenu.length > 0 && (query.index != 1 || query.sx != 1)) {
				let ids = [];
				this.isFour = true;
				this.childrenMenu.forEach((v) => {
					ids.push(v.id);
				});
				this.count = 0;
				console.log(ids);
				this.$api
					.fournews({
						ids: ids,
					})
					.then((res) => {
						this.fourList = res.data;
					});
			} else {
				// 普通加载
				if (catid) {
					this.$api
						.news({
							page: this.page,
							row: 10,
							catid: catid,
						})
						.then((res) => {
							this.$emit('getLoad', true);
							//是否领导
							if (catid == this.arctype.REN_YUAN_GOU_CHENG) {
								this.leader = true;
							}
							//是否讲解
							if (catid == this.arctype.JIANG_JIE_YUAN1 || catid == this.arctype.JIANG_JIE_YUAN2) {
								this.isExplain = true;
							}
							//展览咨询
							if (catid == this.arctype.ZHAN_LAN_ZI_XUN) {
								this.isZxzl = true;
							}
							let list = res.data;
							if (this.leader) {
								list.map((v) => {
									let tempDescription = [];
									let tempArr = v.description.split('\n');
									tempArr.map((t, index) => {
										if (t != '') {
											// let tempNameContent = t.split('：');
											// console.log(tempNameContent);
											let icon = '';
											switch (index) {
												case 0:
													icon =
														'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAg5JREFUWEfF17mLVEEQx/HPgqkGnqkHqKCBoIlooLGJgaCBJp7gbWSohkZeKHgmGih4ZJuugWKiYKCBgkcmnoj+A1LwBmbfzryuHnbXhoZhqupX36nXr7pmzGhrIVZhTRP+Fu/wo1ZurDYAF3FiSNwlnKzRrAX4isWFBN+wJAtRA3AZx5LCV3A845sF2I27GcE+nz24V4rJAtzG3pJYy34H+0oxWYBn2FQSa9mfY3MpJgvwHfHq1ax4JReVArIAfzC3JNay/8W8UkwWIJrMypJYy/6+aVadYVmACWypBHiKraWYLMB+3CyJtewHcKsUkwVYho8lsZZ9OT6VYrIAoXMdB0uCjf0GDmV8awDW42VGFBvwKuNbAxB6UYGoRNeKXx4VSK1agBA9izND1M819lTycBoFYDue/E+ACx1Dx4xWYDV2dZS/V5SjeIwvmeeQeQTbmsSRfE5GFL/xqAEZ74rpAojrNyagncmkw9weICakuJ6nrGEAXSd9VJ6B52MQwEwk70FPgWgDHMbVUX9iMu4IrvV8+wFienmBFUmhUd0+YCNiyprUiOK+j3t/NlbMCTEvTAKYj5+zkR0L8GtQK+7qctPFFn/tTg06A73vTjeNZ910ZWx0XuM+zvfrdjWiHc1Mtxax4xHVrCjxm2bH2XpY04gG+S5t3pAYtWNEj90bu2NsjzE8dnyOk/45Q/sPiKNOIUcu3GUAAAAASUVORK5CYII=';
													break;
												case 1:
													icon =
														'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAuZJREFUWEftljtoU1EYx38ZtFhaq4SKWg2+kOAjTRB8oKhDVQriog4KoqO4KIg4tCAo4iguHURxUDA4CIpDIdIo1qlamrQKIcVqG59Ea0VJRazyhxOItze55yZDFz84BHLO//v+93sHmGEJzLB9/BKYC2wxZ7P5fQP0lpxRPx/lh8Ax4AYgg5+A2cAKIA98ARqB1cBF4ALw04aILYGbwHZgFrDIQ/EAEAUOAXEvEjYE/gCvgWVeykrufwDvgIfAiUo4LwIJYD6wwYdx59MwkCmHr0TgLHAYiNRg/K4J2z6/BJTtE8B3oMEJDgaD4/F4PB2NRpuy2ey3zs7OYE9Pz9oyRh4Dl4F7bvflPLAHuAYscYJisdhwf3//KlN224BHwM729vbB7u7u9S5G+oAHwHk/BM4BB4FpX5XL5fpaWloKpiqKOgfy+XxDc3OziDllBBgGdvshkASWAiudoKmpqYlAINDkouxta2vr73Q6HXK5+2X6xrSrciFQ6bnK5OTkSF1dnfpBaXg+A8H6+vpCoVCY4wJUb4j58cATYB6wzglKJBK9bW1t+lvxL0oqk8k0hsNhdUY3UTKrU1p74BKww/T6aaBUKjUUiURETgm4ZmxsbCQUCm0qY/wFoJAppNYElDDX3aqgqCEajY52dHS86urqWpxMJjUDyslTYAg47oeA3iqu6gXLKyi3uVI+qazVVa09oIengP2OWNsYLH0j9z8HjpYDes0CDaGPwEa/loEPwAITxvfVEthr5ns18+AlcBW4Uom8lweEvWO+RFVhK8/MkqLYVxQbAlKgNUtnq5dC03ZVdmoWaa/3tgS0/2mafTVrVyW9WtdOA7e8jOveloDengSOeCwnKVNuZ2yM+yWg99rxFpou6bRhHfdSoB8PFHEqzZwjHzRutcTssol7rQSUD/eB8ZJ8UM3L7VZxr5WA8AdMjQ+aElWpaonxLdWEoGhENa4GpXDc9m3ZAGohUK3Nf3D/CfwFGO2jIV9Yd5IAAAAASUVORK5CYII=';
													break;
												case 2:
													icon =
														'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAgCAYAAABkWOo9AAAAAXNSR0IArs4c6QAAApVJREFUWEftmFuITVEYx3+TEpKUQmrIs6IkkXlSTJpcEoqEUpTLC0keGDOkIUnJ/RJKRG6TQkkKKSQ88CDJg1t5cE0eRP/xLa1Wa59zZu91nPMw38upvdb6f7/9rfX9996ngb8xGNgHTAEG2LVa/3wCbgPLgXcNRtMInAUm1JouyH8fmAe8dqAa7w2cBmbXCWynQf4Ujw/q+PYAq2oMe8i2/B9GDFSDG4BtNYJtB1rD3ALdBayJQC0Gjv9n2BXA/kjO3QL9DZwH5kQmNANngIFVBv4CLAEuRvJcAmY6UI0/MHv6HEweC5wERlUJ9gWg3bsX6A8CbgKjXTOpoi7eA5OB58Ei2ddRu5GUvHeARcCrQHQccA0QbFf4FfXnyvhvBIv7AAfs7lPAXgAWAj8CMR3Bc7Fm8ivqj+vMnIgQdQDrC5KqYdQ4YawDdsS0syrq5m4CtkQWrgV25oRtAzZH1u7NgC+59b7OYWBZRFhn6xjQq0LgX8DqDPtRt88qpVOuom7tdTtPHwOxacBBQM1WKt4apM6lH0PNksq+Y1QKKvFnwHzgaZBMHaptG59B+tgeyXeD8YnAKWBkJTvSHVDpfQPmmnX4+iMMtiVIqp1YCbwMri8AjgB9K4HUnO6COt2ldj79PP0MVm6h0ENCnf09gMnViHlBlXsjsDVSEdmLdGU1YWgsdr1sYYuASjzLD2OJ5clyilxRFFRJ9YKrzwU9fmMxHJDFTc1FaItSgErqocE+CmDkBHoJHlMEskgzxfLKK1XZKzY43So5pChkalDHc9WzsRSMXRqptj4ZUJZQD2jqEvdUtBoVfQMMSy2cWO+Dtv4yMCOxcGq5ToFOAvQ1WM/R5P7SaQK22zd0/zoh/go8sbe0W38AmpN6b/EP6sIAAAAASUVORK5CYII=';
													break;
												default:
													break;
											}
											tempDescription.push({
												// name:tempNameContent[0],
												content: t,
												icon,
											});
										}
									});
									v.description = tempDescription;
								});
							}
							if (
								catid == this.arctype.GUAN_CANG_JING_PIN ||
								catid == this.arctype.YING_XIANG ||
								catid == this.arctype.KOU_SHU
							) {
								this.isImgList = true;
							}

							res.data.map((item) => {
								item.date = moment(item.timeline).format('YYYY-MM-DD');
								return item;
							});
							if (this.leader) {
								if (list.length > 0) {
									this.leaderList = list.slice(0, 1);
									this.list = list.slice(1, list.length); // 每周推荐
								}
							} else if (this.isZxzl) {
								if (list.length > 0) {
									// while (i++ > 2 && list.length > 0) {
									// 	zxzlList.push(list.shift());
									// }
									this.zxzlList = list.slice(0, 2); // 每周推荐
								}
								this.list = res.data; //
							} else {
								this.list = list;
							}

							this.count = res.count;
						})
						.catch((error) => {
							// error
							console.log(error);
						});
				}
			}
		},
		types() {
			this.$emit('getLoad', true);
			let { query } = this.$route;
			this.index = query.index;
			this.sx = query.sx;
			this.lists();
		},
	},
};
</script>

<style lang="scss">
.category_news_article .custom_roter_link {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.time-title {
	text-align: center;
	margin-bottom: 10px;
}

.time-desc {
	text-align: center;
	margin-bottom: 20px;
	font-size: 12px;
	color: #999;
}

.loadmore {
	color: #fff;
	background-color: #26b6b4;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	line-height: 40px;
}

.ivu-timeline-item-tail {
	height: 100%;
	border-left: 3px solid #e8eaec;
	position: absolute;
	left: 6px;
	top: 0;
}

.time-line-body {
	min-height: 100px;
}

.time-line-body .date {
	font-size: 16px;
	font-weight: bold;
}

.time-line-body .content {
	font-size: 14px;
	margin-top: 15px;
}

.threeList {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: auto;

	.list {
		width: 31%;
		margin-right: 20px;

		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	.img {
		// background: rgb(206, 206, 206);
		width: 100%;
		height: 240px;
	}
}

.threeList img {
	width: 100%;
	height: 240px;
}

.threeList .title {
	height: 20px;
	max-height: 40px;
	width: 100%;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	/*想省略几行就写几*/
	-webkit-box-orient: vertical;
	text-align: center;
	color: #666;
	font-size: 16px;
	padding: 12px;
	margin-bottom: 10px;
}

.leaderList {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: auto;

	.list {
		width: 31%;
		margin-right: 3%;

		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	.img {
		width: 100%;
		height: 240px;

		img {
			width: 100%;
			// height: 260px;
			max-height: 240px;
		}
	}

	.title {
		font-size: 12px !important;
		margin-bottom: 10px;
		margin-top: 4px;
		// height: 200px;
		min-height: 190px;
		width: 100%;
		border-radius: 6px;
		background: #c6dfe3;

		.name {
			padding: 10px 10px 0 28px;
		}

		.desc {
			padding: 0 10px;

			.desclist {
				display: flex;
				align-items: flex-start;

				// justify-content: space-between;
				img {
					margin-top: 6px;
					margin-right: 6px;
					width: 12px;
				}
			}
		}
	}
}

.showLists {
	padding-top: 40px;

	a {
		align-items: flex-start;
	}

	.showLists-content {
		padding: 20px 0;
		display: flex;

		border-bottom: 1px solid #cbcbcb;

		&:last-child {
			border: none;
		}

		.left-pic {
			width: 30%;
			height: auto;

			img {
				width: 100%;
				height: 120px;
			}
		}

		.right-title {
			padding-left: 2%;
			width: 68%;

			.title {
				// width: 260px;
				font-weight: bold;
				font-size: 18px;
			}

			.desc {
				margin-top: 8px;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				font-size: 12px;
			}
		}
	}
}
</style>
